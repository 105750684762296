<template>
  <div class="admin-refGoods_order">
    <div class="title">
      <span class="s" style="color: #ee4d2d; border-color: #ee4d2d"
        >评价管理</span
      >
    </div>
    <div class="search-cell">
      <el-form
        size="mini"
        ref="form"
        :inline="true"
        :model="form"
        label-width="80px"
      >
        <el-form-item>
          <el-select
            style="width: 100px; margin-right: 10px"
            size="mini"
            v-model="value"
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item"
              :value="index"
            >
            </el-option>
          </el-select>
          <el-input
            :placeholder="options[value]"
            style="width: 120px"
            v-model="keyWord"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属学校">
          <el-select
            style="width: 120px"
            v-model="form.school_id"
            placeholder="全部"
            @change="getClassLt"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in schoolList"
              :key="item.id"
              :label="item.school_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属班级">
          <el-select
            style="width: 120px"
            v-model="form.class_id"
            placeholder="全部"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in classList"
              :key="item.id"
              :label="item.class_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="评价时间：">
          <el-date-picker
            size="mini"
            v-model="form.time"
            type="datetimerange"
            @change="handleTimeChange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="
              page = 1;
              getOrderList();
            "
            >查询</el-button
          >
          <el-button plain @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="tab">
      <el-table
        size="small "
        border
        v-loading="loading"
        :data="tableData.list"
        style="width: 100%; text-algin: center"
        :header-cell-style="{ background: '#F2F2F2', 'border-color': '#000' }"
        :row-style="{ 'border-color': '#000' }"
        :cell-style="{ 'border-color': '#000' }"
        :header-row-style="{ 'border-color': '#000' }"
      >
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="id"
          label="序号"
          width="80"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="title"
          label="商品名称"
          width="220"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="content"
          label="评价内容"
          width="180"
        >
          <template slot-scope="scope">
            <div>
              <div style="display: inline-flex; align-items: center">
                商品评分：
                <el-rate
                  style="height: auto"
                  disabled
                  v-model="scope.row.state"
                ></el-rate>
              </div>

              <el-tooltip class="item" effect="dark" placement="top">
                <div slot="content" style="max-width: 200px">
                  评价内容：{{ scope.row.content }}
                </div>
                <div
                  class="single-hidden"
                  style="width: 160px; padding: 0 10px"
                >
                  评价内容：{{ scope.row.content }}
                </div>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="member_name"
          label="评价人"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="shop_name"
          label="店铺名称"
        >
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="school_name"
          label="所属学校"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="class_name"
          label="所属班级"
        >
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="time"
          label="评价时间"
          width="180"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.time | formatTimeS("all") }}</span>
          </template>
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="student_code"
          label="操作"
        >
          <template slot-scope="scope">
            <span class="btn" @click="handleDel(scope.row)">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        small
        style="margin-top: 20px"
        background
        layout="prev, pager, next"
        :page-size="page_size"
        :total="tableData.total"
        :current-page="page"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {
  getSchoolList,
  getClassList,
  commentList,
  commentDel,
} from "@/api/admin.js";
export default {
  data() {
    return {
      form: {
        goods_name: "",
        shop_name: "",
        member_name: "",
        content: "",
        school_id: "",
        class_id: "",
        start_time: "",
        end_time: "",
        time: "",
      },
      orderType: [
        "申请退货",
        "卖家同意",
        "卖家拒绝",
        "申请平台介入",
        "成功退货",
        "退货已关闭",
      ],
      value: 0,
      keyWord: "",
      options: ["商品名称", "评价内容", "评价人", "店铺名称"],
      schoolList: [],
      classList: [],
      tableData: { list: [] },
      page: 1,
      page_size: 10,
      loading: true,
    };
  },
  created() {
    this.getCampusList();
    this.getClassLt();
    this.getOrderList();
  },
  methods: {
    getOrderList() {
      this.loading = true;
      if (this.value == 0) {
        this.form.goods_name = this.keyWord;
      }
      if (this.value == 1) {
        this.form.content = this.keyWord;
      }
      if (this.value == 2) {
        this.form.member_name = this.keyWord;
      }
      if (this.value == 3) {
        this.form.shop_name = this.keyWord;
      }
      commentList({
        ...this.form,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        this.loading = false;
        if (res.code == 1) {
  
          this.tableData = res.data;
        }
      });
    },
    getCampusList() {
      getSchoolList().then((res) => {
        if (res.code == 1) {
          this.schoolList = res.data;
        }
      });
    },
    getClassLt() {
      this.form.class_id = "";
      getClassList({
        school_id: this.form.school_id,
      }).then((res) => {
        this.classList = res.data;
      });
    },
    handleTimeChange() {
      this.form.start_time = parseInt(this.form.time[0].getTime() / 1000);
      this.form.end_time = parseInt(this.form.time[1].getTime() / 1000);
    },

    handleReset() {
      this.form = {
        goods_name: "",
        shop_name: "",
        member_name: "",
        content: "",
        school_id: "",
        class_id: "",
        start_time: "",
        end_time: "",
        time: "",
      };
      this.value = 0;
      this.keyWord = "";
      this.page = 1;
      this.getOrderList();
      this.getClassLt();
    },
    pageChange(index) {
      this.page = index;
      this.getOrderList();
    },
    handleDel(val) {
      this.$confirm(
        "删除评价后，前台商品详情评价不可见，是否确认删除?",
        "删除评价",
        {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        commentDel({
          id: val.id,
        }).then((res) => {
          if (res.code == 1) {
            this.getOrderList();
            this.$message({
              type: "success",
              message: "删除评价成功!",
            });
          }
        });
      });
    },
  },
};
</script>
<style lang="less" >
.admin-refGoods_order {
  background: #fff;
  padding: 20px;
  .title {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    .s {
      font-size: 14px;
      border-bottom: 2px solid transparent;
      padding: 0 10px;
      padding-bottom: 10px;
      cursor: pointer;
    }
  }
  .search-cell {
    padding: 20px 0 0;
  }
  .tab {
    .btn {
      font-size: 12px;
      cursor: pointer;
      margin: 0 10px;
    }
    .btn:hover {
      border-bottom: 1px solid #000;
    }
    .el-rate__icon {
      margin: 0;
      font-size: 16px;
    }
  }
}
.el-form-item__label {
  font-size: 12px;
}
</style>